import { useEffect } from "react";
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';
import Preloader from "../component/Preloader";

export default function Login() {
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  const navigate = useNavigate();
  
  useEffect(() => {
    if (isLoading) return;
    if (isAuthenticated) {
      navigate('/');
    } else {
      loginWithRedirect();      
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);
  
  return <Preloader />;
}
