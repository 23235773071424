import React, { useState, useRef, useEffect } from "react";
import Navbar from "../../component/Navbar.js";
import Footer from "../../component/Footer.js";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../auth.js";
import emailjs from "emailjs-com";
import * as apiService from "../../api-service.js";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import { FaPeopleArrows } from "react-icons/fa";
import { FaUserDoctor } from "react-icons/fa6";
import { RiMentalHealthLine } from "react-icons/ri";
import CalendlyModal from "./CalendlyModal/CalendlyModal.js";
import { GiShinyApple } from "react-icons/gi";
import { FaTooth } from "react-icons/fa";
import { IoIosFitness } from "react-icons/io";

export default function Consultations() {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { user, loading, isPremium } = useAuth();
  const [dataState, setDataState] = useState(undefined);
  const userDataRef = useRef({});
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [alreadyConsulted, setAlreadyConsulted] = useState(false);
  const [telephone, setTelephone] = useState("");
  const [expandedDescriptions, setExpandedDescriptions] = useState([]);
  const isPremium2 = true;

  const navigate = useNavigate();

  function setConsultationDone() {
    localStorage.setItem("consultationDone", "true");
  }

  function hasConsultationBeenDone() {
    return localStorage.getItem("consultationDone") === "true";
  }

  useEffect(() => {
    (async () => {
      if (!loading) {
        if (user) {
          setDataState("loading");
          const userIdToken = await user.getIdToken();
          try {
            const { userData } = await apiService.getUserData({
              userIdToken,
              userId: user.uid,
            });

            userDataRef.current = userData;

            setName(userDataRef.current.name || "");
            setEmail(userDataRef.current.email || "");
            setTelephone(userDataRef.current.telephone || "");
            setDataState("success");
            
            const alreadyConsulted = await apiService.checkIfAlreadyConsulted(
              userIdToken,
              user.uid,
            );
            setAlreadyConsulted(alreadyConsulted)
    
          } catch {
            setDataState("error");
          }
        }
      }
    })();
  }, [user, loading]);

  const handleConsultation = async (type) => {
    const userIdToken = await user.getIdToken();
    setConsultationDone();

    const consultation = await apiService.setConsultation({
      userIdToken: userIdToken,
      userId: user.uid,
      consultation: type,
    });
  };

  const sendEmail = (type) => {
    const consultationDone = hasConsultationBeenDone();

    const serviceId = "sleepzzoneId";
    const templateId = "template_rvzg3v9";
    const userId = "m_F9K9HuFgibYIL2W";

    const templateParams = {
      user_name: name,
      user_phone: telephone,
      user_mail: email,
      profile:
        "Datos extra = premium: " +
        userDataRef.current.premium +
        ", premium_timestamp: " +
        userDataRef.current.premium_timestamp +
        ", premium_type: " +
        userDataRef.current.premium_type +
        ", premium_code: " +
        userDataRef.current.premium_code,
      user_code: user.uid,
      tipo: type,
    };

    emailjs
      .send(serviceId, templateId, templateParams, userId)
      .then(async (response) => {
        console.log("Email sent successfully:", response);
        await handleConsultation(type);
        navigate("/verification/consultas");
      })
      .catch((error) => {
        console.error("Error sending email:", error);
      });
  };

  const toggleDescription = (index) => {
    setExpandedDescriptions((prev) => {
      const updated = [...prev];
      updated[index] = !updated[index];
      return updated;
    });
  };

  const consultants = [
    {
      number: "1",
      img: <FaUserDoctor className="mb-4 w-10 h-10 blue-text" />,
      title: t("Consultations.médico"),
      discription: t("Consultations.textoMedico"),
    },
    {
      number: "2",
      img: <RiMentalHealthLine className="mb-4 w-10 h-10 blue-text" />,
      title: t("Consultations.psicologo"),
      discription: t("Consultations.textoPsicologo"),
    },
    {
      number: "3",
      img: <FaTooth className="mb-4 w-10 h-10 blue-text" />,
      title: t("Consultations.dentista"),
      discription: t("Consultations.textoDentista"),
    },
    {
      number: "4",
      img: <IoIosFitness className="mb-4 w-10 h-10 blue-text" />,
      title: t("Consultations.entrenador"),
      discription: t("Consultations.textoEntrenador"),
    },
    {
      number: "5",
      img: <GiShinyApple className="mb-4 w-10 h-10 blue-text" />,
      title: t("Consultations.nutricionista"),
      discription: t("Consultations.textoNutricionista"),
    },
  ];

  const services = [
    {
      title: t("Consultations.tituloConsulta1"),
      price: 35,
      duration: "15 " + t("Consultations.minutos"),
      description: t("Consultations.descripcionConsulta1"),
      free: isPremium,
    },
    {
      title: t("Consultations.tituloConsulta2"),
      price: 69,
      duration: "30 " + t("Consultations.minutos"),
      description: t("Consultations.descripcionConsulta2"),
      disabled: !alreadyConsulted,
      free: isPremium,
    },
    {
      title: t("Consultations.tituloConsulta5"),
      price: 69,
      duration: "45 " + t("Consultations.hora"),
      description: t("Consultations.descripcionConsulta5"),
      disabled: !alreadyConsulted,
      free: isPremium,
    },
  ];
  const services2 = [
    {
      title: t("Consultations.tituloConsulta3"),
      price: 99,
      duration: "1 " + t("Consultations.hora"),
      description: t("Consultations.descripcionConsulta3"),
      disabled: !alreadyConsulted,
      free: isPremium,
    },
    {
      title: t("Consultations.tituloConsulta4"),
      price: 160,
      duration:
        "1 " + t("Consultations.hora") + " + 45 " + t("Consultations.hora"),
      description: t("Consultations.descripcionConsulta4"),
      disabled: !alreadyConsulted,
      free: isPremium,
    },
  ];

  return (
    <>
      <Helmet>
        <title>
          Consultas: Solicite una vídeoconsulta con expertos del sueño |
          SleepZzone
        </title>

        <meta
          name="description"
          content="Programa una consulta con nuestros expertos en sueño y obtén consejos personalizados para mejorar la calidad de tu descanso y cuidar de tu salud."
        />
        <meta
          name="keywords"
          content="Consultas, Programar consulta, Sueño, Bienestar, Consejos para dormir, Experto en sueño"
        />
      </Helmet>
      <div>
        <Navbar whiteHeader={true} />
        <section className="consultation-outer pt-8 pb-8">
          <div className="wrapper mx-auto px-5 lg:px-0">
            <div className="consult-header pt-20 lg:pb-16 ">
              <h2 className="text-center font-black pb-3 text-3xl lg:text-5xl">
                {t("Consultations.titulo")}
              </h2>
              <p className="text-base lg:text-xl">
                {t("Consultations.subtitulo")}
              </p>
            </div>
            {!user || loading ? (
              <div className="text-center my-8">
                <div className="rounded-lg inline-block text-white blue-btn py-3.5 px-6 font-black border-2">
                  <Link to={`/login?returnTo=/consultations`}>
                    {t("Consultations.botonRegistra")}
                  </Link>
                </div>
              </div>
            ) : !isPremium2 ? (
              <div className="text-center my-8">
                <div className="rounded-lg inline-block text-white blue-btn py-3.5 px-6 font-black border-2">
                  <Link to={`/consultationspage`}>
                    {t("Consultations.botonPremium")}
                  </Link>
                </div>
              </div>
            ) : (
              <div className="consultan-card-outer mb-4">
                <div className="mb-8 px-5">
                  <p className="text-center text-2xl">{t("Consultations.catalogo")}</p>
                  <p className="text-center text-2xl">{t("Consultations.catalogo2")}</p>
                </div>
                <p className="font-black mt-10 " style={{ fontSize: "19px" }}>
                  {t("Consultations.titulo2")}
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4">
                  {services.map((service, index) => (
                    <div
                      key={index}
                      className={"cons-box rounded-2xl p-8 bg-gray-100 shadow-md" + (service.disabled ? ' disabled' : '')}
                      style={{ marginTop: "20px" }}
                    >
                      <div className="cons-detail2">
                        <h4 className="font-black">{service.title}</h4>
                        <p>
                          {t("Consultations.Precio")}: <span className={service.free ? 'free-item' : ''}>{service.price}€</span>
                        </p>
                        <p>
                          {t("Consultations.Duración")}: {service.duration}
                        </p>
                        <CalendlyModal
                          data-testid="CalendlyComponent"
                          index={index}
                          handleConsultation={handleConsultation}
                        />
                        {expandedDescriptions[index] ? (
                          <div>
                            <p className="cons-description mt-5">
                              {service.description}
                            </p>
                            <div className="link-container">
                              <p>
                                <span
                                  className="read-more-link"
                                  onClick={() => toggleDescription(index)}
                                >
                                  {t("Consultations.minimizar")}
                                </span>
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="link-container">
                            <p>
                              <span
                                className="read-more-link"
                                onClick={() => toggleDescription(index)}
                              >
                                {t("Consultations.saberMas")}
                              </span>
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
                <p className="font-black mt-10 " style={{ fontSize: "19px" }}>
                  {t("Consultations.titulo3")}
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-4 ">
                  {services2.map((service, index) => (
                    <div
                      key={index + 3}
                      className={"cons-box rounded-2xl p-8 bg-gray-100 shadow-md" + (service.disabled ? ' disabled' : '')}
                      style={{ marginTop: "20px" }}
                    >
                      <div className="cons-detail2">
                        <h4 className="font-black">{service.title}</h4>

                        <p>
                          {t("Consultations.Precio")}: {service.price}€
                        </p>
                        <p>
                          {t("Consultations.Duración")}: {service.duration}
                        </p>
                        <CalendlyModal
                          data-testid="CalendlyComponent"
                          index={index + 3}
                        />
                        {expandedDescriptions[index + 3] ? (
                          <div>
                            <p className="cons-description mt-5">
                              {service.description}
                            </p>
                            <div className="link-container">
                              <p>
                                <span
                                  className="read-more-link"
                                  onClick={() => toggleDescription(index + 3)}
                                >
                                  {t("Consultations.minimizar")}
                                </span>
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="link-container">
                            <p>
                              <span
                                className="read-more-link"
                                onClick={() => toggleDescription(index + 3)}
                              >
                                {t("Consultations.saberMas")}
                              </span>
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div className="consultan-card-outer mt-20 mb-20">
              <div className="grid lg:grid-cols-3 gap-8 lg:gap-12 justify-center">
                {consultants.map((consultant, index) => {
                  return (
                    <div className="cons-box rounded-2xl p-8">
                      <div className="cons-img flex relative justify-center items-center ">
                        {consultant.img}
                      </div>
                      <div className="cons-detail">
                        <h3 className="font-black">{consultant.title}</h3>
                        <p>{consultant.discription}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
}
