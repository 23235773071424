import { useState,useEffect } from "react";

import * as apiService from '../api-service.js';

const baseLink = 'https://circle.sleepzzone.com/join?invitation_token=df308964f68afd4063c775d068bc32537b7926b3-0ab0b72d-586d-4779-91c2-f02f190f0cab';
const altLink = 'https://circle.sleepzzone.com/join?invitation_token=2c0b73ab95b62ed732f5e8e084f27fa58b403779-9765b2dc-63b6-4d90-952b-205133306aec';

export function useCircleLink(user) {
    const [data, setUserData] = useState(null);
    
    useEffect(() => {
    (async () => {
        if (!user) return;
        const userIdToken = await user.getIdToken();
        try {
            const { userData } = await apiService.getUserData({
                userIdToken,
                userId: user.uid,
            });
            setUserData(userData)
        } catch {}
    })();
    }, [user]);
    
    const mail = String(data?.email);
    if (mail.includes('@mutua.es')) {
        return altLink;        
    }
    return data ? baseLink : '#';
}