const userFields = [
    'name',
    'lastName',
    'telephone',
];
const clinicFields = [
    'genre',
    'age',
    'height',
    'weight',
    'allergy',
    'medication',
];

export function isProfileCompleted(userData, clinicHistory) {
    if (!userData || !clinicHistory) {
        return false;
    }
    const userValid = userFields.every(field => Boolean(userData[field]));
    const clinicValid = clinicFields.every(field => Boolean(clinicHistory[field]));
    return userValid && clinicValid;
}