import React, { useEffect, useState } from 'react';
import QuestionnairLayout from './pages/Questionnaire';
import * as apiService from './api-service.js';
import { useAuth } from './auth';
import Preloader from './component/Preloader.js';
import PreQuestionnair from './pages/Questionnaire/PreQuestionnair';

const CheckQuestionnaire = () => {
  const [completed, setCompleted] = useState(null);
  const [completedAdvanced, setcompletedAdvanced] = useState(null);
  const [completedSF36, setcompletedSF36] = useState(null);
  const [index, setIndex] = useState(-1);
  const { user } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      const userIdToken = await user.getIdToken();
      const index = await apiService.getAssesmentIndex(userIdToken, user.uid);

      if (index !== undefined) {
        if (index >= 1 && index < 9) {
          setIndex(index - 1);
          setcompletedAdvanced(false);
          setCompleted(true);
          setcompletedSF36(false);
        } else if (index == 9) {
          setIndex(0);
          setcompletedAdvanced(true);
          setCompleted(false);
          setcompletedSF36(false);
        } else if (index === 10) {
          setIndex(0);
          setcompletedAdvanced(false);
          setCompleted(false);
          setcompletedSF36(true);
        } else {
          setCompleted(false);
          setcompletedAdvanced(false);
          setcompletedSF36(false);
        }
      }
    };
    
    if (user) {
      fetchData();
    }
  }, [user]);
  return completed === null ||
    completedAdvanced === null ||
    completedSF36 === null ? (
    <Preloader></Preloader>
  ) : index === -1 ? (
    <PreQuestionnair />
  ) : (
    <QuestionnairLayout
      completed={completed}
      completedAdvanced={completedAdvanced}
      completedSF36={completedSF36}
      index={index}
    />
  );
};

export default CheckQuestionnaire;
