
import React from 'react';

export const useLocalStorageState = ({ key, value }) => {
  const data = localStorage.getItem(key);
  const parsedLocalStorage = JSON.parse(!data || data === 'null' ? '{}' : data);
  const initialValue = Object.keys(parsedLocalStorage).length > 0 ? parsedLocalStorage : value;
  const [localStorageState, setLocalStorageState] =   React.useState(initialValue);
  const handleUpdateLocalStorageState = React.useCallback((x) => {
      setLocalStorageState(x);
      localStorage.setItem(key, JSON.stringify(x));
    },
    [key]
  );
  return [localStorageState, handleUpdateLocalStorageState];
};

export const useLocalStorageForm = (form) => {
  return useLocalStorageState({
    key: `form-${form}`,
    value: null,
  })
};
