import { Helmet } from "react-helmet";
import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../../auth.js';
import { useTranslation } from 'react-i18next';
import * as apiService from "../../api-service.js";
import { isProfileCompleted } from "../../helpers/basicForm.js";
import Preloader from "../../component/Preloader.js";
import FooterSmall from '../../component/FooterSmall.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'

const optionsGenre = [
  { key: '', label: 'BasicForm.selectOption' },
  { key: 'Masculino', label: 'ClinicHistory.masculino' },
  { key: 'Femenino', label: 'ClinicHistory.femenino' },
];

const optionsBoolean = [
  { key: '', label: 'BasicForm.selectOption' },
  { key: '1', label: 'BasicForm.si' },
  { key: '0', label: 'BasicForm.no' },
];

const fields = [
  { type: 'text', group: 'userData', field: 'name', title: 'Profile.nombre', placeholder: 'Profile.placeholderNombre', required: true},
  { type: 'text', group: 'userData', field: 'lastName', title: 'Profile.apellido', placeholder: 'Profile.placeholderApellido', required: true},
  { type: 'text', group: 'userData', field: 'telephone', title: 'Profile.numero', placeholder: 'Profile.placeholderNumero', required: true},
  { type: 'select', group: 'clinicHistory', field: 'genre', title: 'ClinicHistory.género', options: optionsGenre, required: true},
  { type: 'number', group: 'clinicHistory', field: 'age', title: 'ClinicHistory.edad',  placeholder: 'ClinicHistory.placeholderEdad', required: true},
  { type: 'number', group: 'clinicHistory', field: 'height', title: 'ClinicHistory.altura', subtitle: 'ClinicHistory.alturaSubtitle', placeholder: 'ClinicHistory.placeholderAltura', required: true},
  { type: 'number', group: 'clinicHistory', field: 'weight', title: 'ClinicHistory.peso', subtitle: 'ClinicHistory.pesoSubtitle', placeholder: 'ClinicHistory.placeholderPeso', required: true},
  { type: 'select', group: 'clinicHistory', field: 'diabetes', title: 'ClinicHistory.diabetes', options: optionsBoolean, required: true},
  { type: 'text', group: 'clinicHistory', field: 'allergy', title: 'ClinicHistory.alergia', placeholder: 'ClinicHistory.placeholderAlergia', required: true},
  { type: 'text', group: 'clinicHistory', field: 'medication', title: 'ClinicHistory.medicacion', placeholder: 'ClinicHistory.placeholderMedicacion', required: true},
];

function ItemTitle({title, subtitle}) {
  return (
    <div className="mb-4">
      <h2 className="text-[32px] font-black mt-4 mx-auto">
        {title}
      </h2>
      {subtitle && (<p className="text-[16px] text-gray-600">{subtitle}</p>)}
    </div>
  );
}
function Item({type, field, group, options, placeholder, required, setValue, value, nextAction}) {
  const { t } = useTranslation();
  const name = `${group}[${field}]`;
  
  useEffect(() => {
    document.getElementById(name).focus();
  }, [name]);
  
  if (type === 'select') {
    return (
      <div className="select-container">
        <select
          id={name}
          name={name}
          key={name}
          className="bg-gray-100 border border-gray-800 rounded-lg  block w-full py-4 px-8"
          defaultValue={value}
          onChange={(event) => {
            setValue(event.target.value);
          }}
        >
          {options.map(({ label, key }) => {
            return (
              <option value={key}>{t(label)}</option>
            );
          })}
        </select>
        <FontAwesomeIcon icon={faChevronDown} />
      </div>
    );
  }
  
  return (
    <>
      <input
        id={name}
        key={name}
        name={name}
        type={type}
        className="bg-gray-100 border border-gray-800 rounded-lg  block w-full py-4 px-8"
        placeholder={placeholder}
        defaultValue={value}
        onKeyUp={(event) => {
          const value = event.target.value;
          setValue(value);
          if(event.key === 'Enter') {
            if (required && !value) {
              return;
            }
            nextAction();
          }
        }}
        required={required}
      />
    </>
  );
};

const getBasicData = (data) => {
  const basicData = {
    userData: {
      email: data?.userData?.email,
      premium: data?.userData?.premium,
      premium_code: data?.userData?.premium_code,
      premium_timestamp: data?.userData?.premium_timestamp,
      premium_type: data?.userData?.premium_type,
    },
    clinicHistory: {
      imc: data?.clinicHistory?.imc || null,
    },
  };
  if (!data) {
    return basicData;
  }
  fields.forEach(({ group, field }) => {
    if (data[group]) {
      basicData[group][field] = data[group][field];
    }
  });  
  return basicData;
}

function Form({ data, userAuth }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [field, setField] = useState(0);
  const currentField = fields.at(field);
  const [dataForm, setDataForm] = useState(getBasicData(data));
  const navigate = useNavigate();
  
  const currentValue = currentField?.group && dataForm[currentField.group] ? (dataForm[currentField.group][currentField.field] || "") : "";
  const nextDisabled = currentField?.required && !currentValue;
  
  const setValue = useCallback((value) => {
    if (currentField?.field) {
      setDataForm((prevForm) => {
        const form = { ...prevForm };
        if (!form[currentField.group]) {
          form[currentField.group] = {};
        }
        form[currentField.group][currentField.field] = value;
        return {...form};
      });    
    }
  }, [currentField?.group, currentField?.field]);
  
  if (!data || !currentField) return null;
  
  const lastField = field >= fields.length - 1;
    
  const handleBack = () => {
    setField(field => field - 1);
  }
  const handleNext = () => {
    setField(field => field + 1);
  }
  const handleSubmit = async () => {
    setLoading(true)
    const { clinicHistory, userData } = dataForm;
    const userDataPromise = apiService.updateUserData({
      ...userAuth,
      userData,
    });
    clinicHistory.diabetes = Number(clinicHistory.diabetes);
    const clinicHistoryPromise = apiService.setClinicHistoryData({
      ...userAuth,
      clinicHistory,
    });
    try {
      await Promise.all([userDataPromise, clinicHistoryPromise]);
      apiService.clearUserData();
      setField(0);
      navigate('/profile');
    } catch (e) {
      console.error(e);
    }
    setLoading(false);      
  }
  
  return (
    <div>
      <div className="header-question">
        <div className="container mx-auto">
          <div className="logo-top flex items-center py-5">
            <a href="/" className="login-logo mr-8">
              <img
                className="inline-block"
                src="/images/logo-blue.svg"
                alt=""
              />
            </a>
            <p className="opacity-50 font-semibold	text-xl">
              {t('BasicForm.title')}
            </p>
          </div>
        </div>
        <div className="border-gradiant"></div>
      </div>
      <div className="wrapper mx-auto">
        <div className="question-main py-16">
          <div className={`mx-auto max-w-[600px] basicForm ${loading ? ' loading' : ''}`}>
            <ItemTitle title={t(currentField.title)} subtitle={currentField.subtitle ? t(currentField.subtitle) : undefined} />
            <Item
              {...currentField}
              placeholder={t(currentField.placeholder)}
              value={currentValue}
              setValue={setValue}
              nextAction={handleNext}
            />
            <div className="flex items-center mt-8">
              {field > 0 && (
                <button
                  className="h-[64px] px-8 mr-3 backbtn rounded-lg"
                  onClick={handleBack}
                  type="button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="33"
                    height="32"
                    viewBox="0 0 33 32"
                    fill="none"
                  >
                    <path
                      d="M9.37687 16.7564L17.5666 24.9333L16.5 25.9999L6.5 16L16.5 6L17.5666 7.06667L9.37687 15.2436H26.4999V16.7564H9.37687Z"
                      fill="#5723E8"
                    />
                  </svg>
                </button>
              )}
              {lastField ? (
                <button
                  className="rounded-lg inline-block text-white w-full blue-btn h-[64px] px-6 font-black border-2"
                  type="button"
                  onClick={handleSubmit}
                  disabled={nextDisabled}
                >
                  {loading ? t('Profile.actualizando') : t('BasicForm.Finalizar')}
                </button>
              ) : (
                <button
                  className="rounded-lg inline-block text-white w-full blue-btn h-[64px] px-6 font-black border-2"
                  type="button"
                  onClick={handleNext}
                  disabled={nextDisabled}
                >
                  {t('BasicForm.Siguiente')}
                </button>
              )}
            </div>  
          </div>
        </div>
      </div>
      <FooterSmall />
    </div>
  );
};

export default function BasicForm() {
  const { user, loading } = useAuth();
  const [dataState, setDataState] = useState('loading');
  const navigate = useNavigate();
  const [dataForm, setDataForm] = useState(null);
  const [userAuth, setUserAuth] = useState(null);

  useEffect(() => {
    (async () => {
      if (loading || !user) return;
      const userIdToken = await user.getIdToken();
      try {
        const data = await apiService.getUserData({
          userIdToken,
          userId: user.uid,
        });
        
        const { userData, clinicHistory } = data;
        
        if (isProfileCompleted(userData, clinicHistory)) {
          navigate('/profile');
          return;
        }
        setDataForm(data);        
        setUserAuth({
          userIdToken,
          userId: user.uid,
        });        
        setDataState('success');
      } catch {
        setDataState('error');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loading]);
  
  if (dataState === 'loading' || !dataForm) {
    return <Preloader />;
  }
  
  if (dataState === 'error') {
    navigate('/');
    return null;
  }
  
  return (
    <>
      <Helmet>
        <title>
          Perfil: Rellena tus datos para poder
          saber más de ti y ayudarte a dormir mejor de manera personalizada |
          SleepZzone
        </title>
        <meta
          name="description"
          content="Con tu pefil completo, podrás conocer más sobre tus hábitos de sueño, consejos para dormir mejor y cómo tus experiencias pueden ayudarte a lograr una noche de sueño más reparadora."
        />
        <meta
          name="keywords"
          content="Dormir, descansar, bienestar, descanso, sueño reparador, consejos para dormir, rendimiento, aprender a dormir"
        />
      </Helmet>
      <Form data={dataForm} userAuth={userAuth} />
    </>
  );
}
