import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaWpforms, FaRegImages } from "react-icons/fa";
import { FaUserDoctor } from "react-icons/fa6";
import { BiSolidBot } from "react-icons/bi";
import { MdEditDocument } from "react-icons/md";
import { useTranslation } from "react-i18next";

function SideMenu() {
  const { t } = useTranslation();

  const [isBotVisible, setIsBotVisible] = useState(false);
  const botContainerId = "sleepbot-container"; // ID del contenedor del bot

  const botScriptId = "sleepbot-script"; // ID del script

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const loadBot = () => {
    let botContainer = document.createElement("div");
    botContainer.id = botContainerId;
    document.body.appendChild(botContainer);

    let script = document.createElement("script");
    script.id = botScriptId;
    script.src = "https://chat-component-svelte2.netlify.app/build/bundle.js";
    script.setAttribute("data-id", "x2nOIUYWBnhsWmSMHgiH");
    botContainer.appendChild(script);
  };
  const removeBot = () => {
    const botContainer = document.getElementById(botContainerId);
    if (botContainer) {
      botContainer.remove();
    }
    const botScript = document.getElementById(botScriptId);
    if (botScript) {
      botScript.remove();
    }
    const botGeneratedElements = document.querySelectorAll(
      ".app__container, .app__chat-chatBot"
    );
    botGeneratedElements.forEach((element) => {
      element.remove();
    });

    if (
      window.chatBotInstance &&
      typeof window.chatBotInstance.destroy === "function"
    ) {
      window.chatBotInstance.destroy();
    }
  };
  const toggleBot = () => {
    if (isBotVisible) {
      removeBot();
    } else {
      loadBot();
    }
    setIsBotVisible(!isBotVisible);
  };

  return (
    <div className="menu">
      <Link to="/questionnair" className="menu-link transition duration-500">
        <FaWpforms class="w-1/4 mb-4" />
        <span>{t("SideMenu.cuestionario")}</span>
      </Link>
      <Link to="/consultations" className="menu-link transition duration-500">
        <FaUserDoctor class="w-1/4 mb-4" />
        <span>{t("SideMenu.consultas")}</span>
      </Link>
      <a
        href="/documents/sleepDiary.xlsx"
        className="menu-link transition duration-500"
        target="_blank"
        download="diario-del-sueno.xlsx"
      >
        <MdEditDocument class="w-1/4 mb-4" />
        <span>{t("SideMenu.diario")}</span>
      </a>
      <Link to="/gallery" className="menu-link transition duration-500">
        <FaRegImages class="w-1/4 mb-4" />
        <span>{t("SideMenu.galeria")}</span>
      </Link>
      <Link to="/sleepbot" className="menu-link transition duration-500">
        <BiSolidBot class="w-1/4 mb-4" />
        <span>{t("SideMenu.sleepbot")}</span>
      </Link>
    </div>
  );
}

export default SideMenu;
